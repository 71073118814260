import { Box } from '@mui/joy'
import Image from 'next/image'
import Link from 'next/link'
import { Text } from '../../Text'
import { Flex } from '../../Flex'

export default function ProductBlock({ src, title, description, btnName, to }) {
    return (
        <Box
            height="100%"
            // borderRadius="8px"
            backgroundColor="#e3eff6"
            border="3px solid transparent"
            sx={{
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover': {
                    borderColor: '#ffd44d',
                },
            }}
        >
            <Flex
                height="100%"
                flexDirection="column"
                justifyContent="space-between"
                p={{
                    xs: 4,
                    sm: 4,
                    md: 6,
                }}
            >
                <Flex justifyContent="center">
                    {src && (
                        <Image
                            quality={80}
                            src={src}
                            width={100}
                            height={100}
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    )}
                </Flex>

                <Text
                    flex={1}
                    pb={2}
                    fontSize={{
                        md: '26px',
                        sm: '21px',
                        xs: '32px',
                    }}
                    textAlign="center"
                    color="#0d417d"
                    fontWeight={700}
                >
                    {title}
                </Text>
                <Text
                    flex={1}
                    pb={2}
                    fontSize="1rem"
                    textAlign="center"
                    color="#0d417d"
                    fontWeight={300}
                    style={{
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {description}
                </Text>
                <Link href={to || '#'}>
                    <Text
                        mb={2}
                        color="#0d417d"
                        fontSize="1.25rem"
                        fontWeight={700}
                        textAlign="center"
                        sx={{
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                    >
                        {btnName}
                    </Text>
                </Link>
            </Flex>
        </Box>
    )
}

import { Grid } from '@mui/joy'
// import Link from 'next/link'
import { Section } from '../../Section'
import ProductBlock from '../common/ProductBlock'

export default function Products({ blocks }) {
    return (
        <Section
            pt={{
                xs: 10,
                sm: 10,
                md: 5,
                lg: 10,
            }}
            pb={{
                xs: 20,
                sm: 30,
            }}
            backgroundColor="#0d417d"
        >
            <Grid
                container
                spacing={{
                    xs: 10,
                    sm: 10,
                    md: 10,
                    lg: 15,
                }}
                columnSpacing={{
                    xs: 0,
                    sm: 0,
                    md: 10,
                    lg: 15,
                }}
            >
                {blocks.map((item, index) => (
                    <Grid // eslint-disable-next-line react/no-array-index-key
                        key={`Product_Block_${index}`}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                    >
                        <ProductBlock
                            src={item.image.url}
                            title={item.title}
                            description={item.description}
                            btnName={item.buttonName}
                            to={item.buttonLink}
                        />
                    </Grid>
                ))}
            </Grid>
        </Section>
    )
}
